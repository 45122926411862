const clients = [
  {
    name: 'Hewlett-Packard',
    company: 'ABC Tech Solutions',
    imageUrl: '/images/LOGO1.jpg',
  },
  {
    name: 'TIIPS, GREATER NOIDA',
    company: 'XYZ Global Inc.',
    imageUrl: '/images/LOGO2.jpg',
  },
  {
    name: 'MSI, JANAKPURI',
    company: '123 Enterprises',
    imageUrl: '/images/LOGO3.jpg',
  },
  {
    name: 'Nokia',
    company: 'Global Innovations',
    imageUrl: 'images/LOGO4.jpg',
  },
  {
    name: 'Radisson Hotel Group',
    company: 'ABC Tech Solutions',
    imageUrl: '/images/LOGO5.jpg',
  },
  {
    name: 'Amarpali Sapphire',
    company: 'XYZ Global Inc.',
    imageUrl: '/images/LOGO6.jpg',
  },
  {
    name: 'GD Pharma',
    company: '123 Enterprises',
    imageUrl: 'images/LOGO7.jpg',
  },
  {
    name: 'IPSAA Pre-School',
    company: 'Global Innovations',
    imageUrl: 'images/LOGO8.jpg',
  },
  {
    name: 'Skytech Group',
    company: 'ABC Tech Solutions',
    imageUrl: '/images/LOGO9.jpg',
  },
  {
    name: 'Paradise Consultancy',
    company: 'XYZ Global Inc.',
    imageUrl: '/images/LOGO10.jpg',
  },
  {
    name: 'Shalimar',
    company: '123 Enterprises',
    imageUrl: 'images/LOGO11.jpg',
  },
  {
    name: 'CESPL Consultancy',
    company: 'Global Innovations',
    imageUrl: 'images/LOGO12.jpg',
  },
];

export default function Clients() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="max-w-2xl mx-auto text-center">
          <h2 className="mb-4 text-2xl tracking-tight font-extrabold text-center text-gray-900 dark:text-black">
            Our Esteemed Clients
          </h2>
          <p className="mb-8 lg:mb-16 font-light text-center text-gray-500 dark:text-gray-400 sm:text-xl">
            Below are some of the prestigious clients we have worked with over the years.
          </p>
        </div>
        <ul
          role="list"
          className="mt-16 grid grid-cols-2 gap-x-8 gap-y-12 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6" // Changed to allow 6 columns on large screens
        >
          {clients.map((client) => (
            <li key={client.name}>
              <div className="mb-9 flex flex-col items-center justify-center rounded-[20px] bg-white p-5 shadow-2 hover:shadow-lg dark:bg-dark-2 md:px-5 xl:px-7 hover:scale-110 transition-transform duration-300"> {/* Adjusted padding */}
                <img
                  alt={`${client.name} from ${client.company}`}
                  src={client.imageUrl}
                  className="h-24 w-24 mb-4 rounded-lg object-cover"
                />
                <div className="text-center">
                  <h3 className="text-body-color dark:text-dark-6 text-center text-gray-500 dark:text-gray-400">
                    {client.name}
                  </h3>
                  {/* Removed company name */}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
