import React from 'react';
import { Link } from 'react-scroll'; // Import Link from react-scroll

const navigation = [
  { name: 'Home', href: 'hero' }, // Use the ID for smooth scrolling
  { name: 'Services', href: 'services' },
  { name: 'Testimonial', href: 'testimonials' },
  { name: 'About', href: 'about' },
  { name: 'Contact', href: 'contact' },
];

export default function Header() {
  return (
    <nav className="bg-white-800">
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div className="relative flex h-16 items-center justify-between">
          <div className="flex flex-1 items-center justify-start sm:items-stretch sm:justify-start">
            <div className="flex items-center">
              {/* Logo */}
              <img
                alt="Your Company"
                src="/images/logo.png" // Replace with your local image path
                className="h-8 w-auto"
              />
              {/* Company Name */}
              <span className="ml-2 text-black text-lg font-semibold">Numaris Engineering</span>
            </div>
          </div>

          {/* Navigation Menu */}
          <div className="hidden sm:ml-6 sm:block">
            <div className="flex space-x-4">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  spy={true}
                  smooth={true}
                  duration={500}
                  className="text-black-300 hover:bg-red-900 hover:text-white rounded-md px-3 py-2 text-sm font-medium cursor-pointer hover:scale-110 transition-transform duration-300"
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
