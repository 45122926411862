export default function Hero() {
  return (
    <div className="relative overflow-hidden bg-white">
      <div className="pt-4 sm:pb-40 sm:pt-24 lg:pb-48 lg:pt-40">
        <div className="relative mx-auto max-w-7xl px-4 sm:static sm:px-6 lg:px-8">
          
          {/* Background Image for smaller screens */}
          <div className="sm:max-w-lg lg:hidden overflow-hidden rounded-lg lg:opacity-100">
            <img
              alt="Hero Background"
              src="images/herobg.jpg"
              className="h-full w-full object-cover object-center"
            />
          </div>

          {/* Title and Description */}
          <div className="sm:max-w-lg">
            <h2 className="mb-4 text-2xl tracking-tight font-extrabold text-justify text-gray-900 dark:text-black">
              Numaris Engineering
            </h2>
            <p className="mb-8 lg:mb-16 font-light text-justify text-gray-500 dark:text-gray-400 sm:text-xl">
              Numaris Engineering (I) Pvt Ltd is a premier engineering firm specializing in comprehensive solutions across multiple sectors including firefighting, electrical systems, security, plumbing, and general trading. With a commitment to excellence and innovation, we deliver high-quality services designed to meet the diverse needs of our clients.
            </p>
          </div>

          {/* Decorative Image Grid */}
          <div className="mt-10">
            <div
              aria-hidden="true"
              className="pointer-events-none lg:block hidden lg:absolute lg:inset-y-0 lg:mx-auto lg:w-full lg:max-w-7xl sm:mx"
            >
              <div className="absolute transform sm:left-1/2 sm:top-0 sm:translate-x-8 lg:left-1/2 lg:top-1/2 lg:-translate-y-1/2 lg:translate-x-8">
                <div className="flex items-center space-x-6 lg:space-x-8">

                  {/* First Column of Images */}
                  <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                    <div className="h-64 w-44 overflow-hidden rounded-lg lg:opacity-100">
                      <img
                        alt=""
                        src="/images/1.png"
                        className="h-full w-full object-cover object-center"
                      />
                    </div>
                    <div className="h-64 w-44 overflow-hidden rounded-lg">
                      <img
                        alt=""
                        src="/images/2.jpg"
                        className="h-full w-full object-cover object-center"
                      />
                    </div>
                  </div>

                  {/* Second Column of Images */}
                  <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                    <div className="h-64 w-44 overflow-hidden rounded-lg">
                      <img
                        alt=""
                        src="/images/33.jpg"
                        className="h-full w-full object-cover object-center"
                      />
                    </div>
                    <div className="h-64 w-44 overflow-hidden rounded-lg">
                      <img
                        alt=""
                        src="/images/4.jpg"
                        className="h-full w-full object-cover object-center"
                      />
                    </div>
                    <div className="h-64 w-44 overflow-hidden rounded-lg">
                      <img
                        alt=""
                        src="/images/3.jpg"
                        className="h-full w-full object-cover object-center"
                      />
                    </div>
                  </div>

                  {/* Third Column of Images */}
                  <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                    <div className="h-64 w-44 overflow-hidden rounded-lg">
                      <img
                        alt=""
                        src="/images/5.jpg"
                        className="h-full w-full object-cover object-center"
                      />
                    </div>
                    <div className="h-64 w-44 overflow-hidden rounded-lg">
                      <img
                        alt=""
                        src="/images/6.jpg"
                        className="h-full w-full object-cover object-center"
                      />
                    </div>
                  </div>

                </div>
              </div>
            </div>

            {/* Explore Services Button */}
            <a
              href="#services"
              className="hidden lg:inline-block rounded-md border-2 border-grey bg-white-600 px-8 py-3 text-center font-medium text-black hover:bg-red-900 hover:text-white border-red-900 hover:scale-110 transition-transform duration-300"
            >
              Explore our services
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
