import React from 'react';

export default function Footer() {
  return (
    <footer className="bg-black text-white py-6">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col md:flex-row justify-between items-center">
          {/* Footer left - Company Name */}
          <div className="text-center md:text-left mb-4 md:mb-0">
            <h3 className="text-lg font-semibold">Numaris Engineering (I) Private Limited</h3>
            <p className="text-sm">&copy; {new Date().getFullYear()} numaris engineering. All rights reserved.</p>
          </div>

          {/* Footer right - Social Links (Optional) */}
          <div className="flex space-x-6">
          <a href="#hero" className="hover:text-gray-400">Home</a>
            <a href="#contact" className="hover:text-gray-400">Contact</a>
          </div>
        </div>
      </div>
    </footer>
  );
}
