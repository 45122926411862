import React from "react";

const Service = () => {
  return (
    <section className="pb-12 pt-20 dark:bg-dark lg:pb-[90px] lg:pt-[120px]">
      <div className="container mx-auto">
        <div className="mx-4 flex flex-wrap">
          <div className="w-full px-4">
            <div className="mx-auto mb-8 max-w-[510px] text-center lg:mb-20">

                <h2 className="mb-4 text-2xl tracking-tight font-extrabold text-center text-gray-900 dark:text-black">Our Services</h2>
              <p className="mb-8 lg:mb-16 font-light text-center text-gray-500 dark:text-gray-400 sm:text-xl">
              Expert Solutions Tailored to Your Safety Needs
              </p>
            </div>
          </div>
        </div>

        <div className="mx-.5 flex flex-wrap">
          <ServiceCard
            title="Fire Protection And Extingushment"
            details="We offer fire protection solutions, including alarm panels and detectors, ensuring early detection and reliable safety tailored to each environment's needs."
            icon="/images/FireFighting.jpg"
          />
          <ServiceCard
            title="Access Control And Security System"
            details="We offer advanced security solutions, including biometric access, CCTV surveillance, and intrusion alarms, for reliable protection tailored to residential and commercial properties."
            icon="/images/AccessControl.jpg"
          />
          <ServiceCard
            title="Plumbing And Electrical Services"
            details="We specialize in high-end plumbing and electrical services in Delhi NCR, offering installations, repairs, and eco-friendly solutions focused on quality."
            icon="/images/Plumbing.jpg"
          />
          <ServiceCard
            title="General Trading"
            details="We source and supply high-quality materials and equipment through partnerships with leading manufacturers, ensuring competitive pricing and exceptional customer service."
            icon="/images/GeneralTrading.jpg"
          />
        
        </div>
      </div>
    </section>
  );
};

export default Service;

const ServiceCard = ({ icon, title, details }) => {
  return (
    <div className="w-full px-2 md:w-1/2 lg:w-1/4"> {/* Change to w-1/4 for larger screens */}
      <div className="mb-9 rounded-[20px] bg-white p-5 shadow-2 hover:shadow-lg dark:bg-dark-2 md:px-5 xl:px-7 hover:scale-105 transition-transform duration-300"> {/* Adjusted padding */}
        <div className="mb-4 flex items-center justify-center h-full w-full rounded-2xl bg-primary overflow-hidden">
          <img
            src={icon}
            alt="Service Icon"
            className="h-full w-full object-cover"
          />
        </div>
        <h4 className="mb-[10px] text-lg font-semibold text-dark dark:text-black text-center"> {/* Adjusted text size */}
          {title}
        </h4>
        <p className="text-body-color dark:text-dark-6 text-justify text-gray-500 dark:text-gray-400">{details}</p>
      </div>
    </div>
  );
};
