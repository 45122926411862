import React from 'react';
import { Link, animateScroll as scroll } from 'react-scroll'; // Import react-scroll
import Header from './components/Header'; // Adjust path if needed
import Hero from './components/Hero';
import Service from './components/Services';
import Footer from './components/Footer';
import About1 from './components/About';
import ContactUs from './components/Contact';
import Testimonials from './components/Testimonial';

import './App.css'; // Import your custom CSS

function App() {
  return (
    <div className="App">
      {/* Header with react-scroll Links */}
      <Header />
      
      {/* Sections with ids to scroll to */}
      <section id="hero">
        <Hero />
      </section>
      
      <section id="services">
        <Service />
      </section>
      
      <section id="testimonials">
        <Testimonials />
      </section>
      
      <section id="about">
        <About1 />
      </section>
      
    
      <section id="contact">
        <ContactUs />
      </section>

      <Footer />
    </div>
  );
}

export default App;
